import $ from 'jquery';
import 'lazysizes';
import 'materialize-css';

$(() => {

    // Materialize Form Init
    M.FormSelect.init(document.querySelectorAll('select'))

    lazySizes.init();


    $('.teaser-kacheln__item').hover(function() {
        $(this).find("video")[0].play();
    }, function () {
        var el = $(this).find("video")[0];
        el.pause();
        el.currentTime = 0;
    });


    // Navigation Touch Geräte
    $('ul.navbar-nav > li.parent > a').click(function(e) {
        e.preventDefault();
        if($(this).closest('.hovered').hasClass('hovered')) {
            $(this).closest('.hovered').removeClass('hovered');
        } else {
            $(this).parent().addClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });
    $('ul.dropdown-menu-ul > li.parent > a').click(function(e) {
        e.preventDefault();
        $(this).parent().addClass('hovered');
        $(this).parent().siblings().removeClass('hovered');
    });

    $('.dropdown-nav__closer, .navbar-nav > li.hovered').click(function() {
        $(this).closest('.hovered').removeClass('hovered');
    });


    // Lift Navigation
    $('.col--liftnav').each(function() {
        $(this).parent().parent().addClass('container container--liftnav');
    });

    // Lift Navigation
    $('.col--civist').each(function() {
        $(this).parent().parent().addClass('container container--civist');
    });

    // Akkordeon
    $('.wly-accordeon__title').click(function() {
        $(this).parent().toggleClass('active');
        $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

        $('.wly-accordeon__item').each(function() {
            if ($( this ).hasClass('active')) {
                $(this).children('.wly-accordeon__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).children('.wly-accordeon__content').slideUp();
            }
        });
    });

    // Mobile Footer Akkoredon
    jQuery(window).resize(function(){
        if (window.matchMedia('(max-width: 600px)').matches) {
            $('.footer__accordeon .footer__content').css('display','none');
        } else {
            $('.footer__accordeon .footer__content').css('display','block');
        }
    });
    if (window.matchMedia('(max-width: 600px)').matches) {
        $('.footer__accordeon .h4').click(function() {
            $(this).toggleClass('active');
            $(this).siblings('.footer__content').slideToggle();
        });
    }



    // Search opener
    $('.search__opener').click(function() {
        $('.main-navigation__search').addClass('active');
    });
    $('.search__closer').click(function() {
        $('.main-navigation__search').removeClass('active');
    });

    // Language Dropdown
    $('.dropdown-toggle').click(function() {
        $(this).closest('.dropdown').toggleClass('active');
    });

    // Scroll to
    $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
        if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu') {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
            }, 1000);
        }
    });

    // Header/Image Breadcrumb Height
    function getBreadcrumbHeight() {
        var breadcrumbHeight = $('.breadcrumb__wrapper').outerHeight();
        if ( $('.header-title-image:not(.header-title-image--no-image)').length ) {
            $('.header-title-image:not(.header-title-image--no-image)').css('margin-top', '-' + breadcrumbHeight + 'px');
            $('.breadcrumb__wrapper').addClass('breadcrumb--small');
        }
    }
    getBreadcrumbHeight();
    $( window ).resize(function() {
        getBreadcrumbHeight();
    });


    // Make fixed Navigation
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    });

    // Downloads show more
    $(".downloads--load-more .downloads__item__wrapper").slice(0, 7).show();
    if ($(".downloads--load-more a:hidden").length != 0) {
        $(".load-more-button").css('display', 'inline-block');
    }
    $(".load-more-button").on("click", function(e) {
        $(".downloads--load-more .downloads__item__wrapper:hidden").slice(0, 7).slideDown();
        if ($(".downloads--load-more .downloads__item__wrapper:hidden").length == 0) {
            $(".load-more-button").hide();
        }
    });


    // Mega Menü Hight Calc
    $('#headerNavbar ul.navbar-nav > li.parent').click(function () {
        var t = 0; // the height of the highest element (after the function runs)
        var childElem = $(this).find('.submenu__wrapper');
        $(childElem).each(function () {
            console.log($(this));
            var elem = $(this);
            elem.height('auto');
            if (elem.height() > t) {
                t = elem.height();
            }
        });
        $(childElem).height(t);
    });


    // Megafilter open Mobile Items
    /*$('.filter__content__closer').click(function() {
        $(this).closest('.filter__block').toggleClass('active');
        $(this).closest('.filter__block').siblings('.filter__block').removeClass('active');

        $('.filter__block').each(function() {
            if ($( this ).hasClass('active')) {
                $(this).find('.filter__block__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).find('.filter__block__content').slideUp();
            }
        });
    });*/
});
