import jQuery from 'jquery';

const $ = jQuery;
import Isotope from 'isotope-layout';
import {slideDown, slideUp, slideStop, isVisible} from 'slide-anim/dist/slide-anim.min';

class Podcast {

    constructor() {
        this.i18n = (JSON.parse(JSON.stringify(vuei18n.messages)))[vuei18n.locale];
        this.isotopeInstances = [];
        this.isoptpeInit();
        this.initPodcast();
    }

    rearrangePodcastGrid() {
        if (this.isotopeInstances.length > 0) {

            this.isotopeInstances.forEach(isotopeInstance => {
                console.log(isotopeInstance);
                isotopeInstance.layout();
            });
        }
    }

    openPodcast(elementToOpen) {
        this.rearrangePodcastGrid();
    }

    initPodcast() {
        // Filter Navigation fixed
        if ($('.podcast-filter').length) {
            $('body').addClass('podcast-filter__exists');
            var distance = $('.podcast-filter').offset().top - 100;
            var $window = $(window);

            $window.bind('scroll', function () {
                if ($window.scrollTop() >= distance) {
                    $('body').addClass('fixed-podcast-filter');
                } else {
                    $('body').removeClass('fixed-podcast-filter');
                }
            });
        }


        // Open Podcasts
        $('.podcasts__podcast__arrow').click((podcast) => {
            if (!$(podcast.currentTarget).hasClass("open")) {
                let elementToOpen = $(podcast.currentTarget).parent().siblings('.podcasts__podcast__detail').get()[0];
                $('.podcasts__podcast__arrow').removeClass('open');
                $('.podcasts__podcast').removeClass('active');

                const openPodcasts = document.querySelectorAll('.podcasts__podcast__detail[style*="display: block"]');
                openPodcasts.forEach(openPodcast => {
                    slideUp(openPodcast, {
                        duration: 500,
                        display: 'block'
                    }).then(() => {
                        this.rearrangePodcastGrid();
                    });
                });

                $(podcast.currentTarget).addClass('open');
                $(podcast.currentTarget).parents('.podcasts__podcast').addClass('active');
                slideDown(elementToOpen, {
                    duration: 500,
                    display: 'block'
                }).then(() => {
                    this.openPodcast(elementToOpen);
                });
            } else {
                $('.podcasts__podcast__arrow').removeClass('open');
                $('.podcasts__podcast').removeClass('active');
                const openPodcasts = document.querySelectorAll('.podcasts__podcast__detail[style*="display: block"]');
                openPodcasts.forEach(openPodcast => {
                    slideUp(openPodcast, {
                        duration: 500,
                        display: 'block'
                    }).then(() => {
                        this.rearrangePodcastGrid();
                    });
                });


            }
        });
    }


    // Isotope
    // https://codepen.io/shainanigans-1472169587/pen/RKEjzz
    isoptpeInit() {

        var categoryFilters = [];
        var categoryFilter;
        if (document.querySelector('.podcastgrid') !== null) {

            const podcastGrids = document.querySelectorAll('.podcastgrid');



            podcastGrids.forEach(podcastGrid => {


                let isotopeInstance = new Isotope(podcastGrid, {
                    // options
                    itemSelector: '.grid-item',
                    layoutMode: 'masonry',
                    masonry: {
                        gutter: 50
                    },
                    filter: function () {
                        var $this = $(this);

                        if (categoryFilters.length === 0) {
                            return true;
                        }
                        // category
                        var categoryResult = true;
                        for (var i = 0; i < categoryFilters.length; i++) {
                            var categoriesStr = $this.data('podcastcategory') + '';
                            var categoriesArr = categoriesStr.split(',');
                            if (categoriesArr.includes(categoryFilters[i])) {
                                categoryResult = true;
                            } else {
                                categoryResult = false;
                            }
                        }
                        // apply filters
                        return categoryResult;
                    }
                });
                this.isotopeInstances.push(isotopeInstance);

            });


            // bind filter button click
            $('.podcast-filter__content__single').on('click', (podcast) => {

                var catid = $(podcast.currentTarget).attr('data-filter');
                if (catid === '*') {
                    categoryFilters = [];
                } else {
                    categoryFilters = [catid];
                }

                this.isotopeInstances.forEach(isotopeInstance => {
                    isotopeInstance.arrange();
                });
            });

            setTimeout(() => {
                this.isotopeInstances.forEach(isotopeInstance => {
                    isotopeInstance.arrange();
                });
            }, 450);
        }

        // change is-checked class on buttons
        $('.button__filter').on('click', (podcast) => {
            $(podcast.currentTarget).siblings().removeClass('is-checked');
            $(podcast.currentTarget).addClass('is-checked');
        });
    }

};

export default new Podcast();