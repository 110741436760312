import {updateActiveItem} from "./navigation.ts";
import {onReady} from "./ready.ts";
import Accordion from "./accordion.ts";
import Autosize from "./autosize.ts";
import Swiper from "./swiper.ts";

import './isotope'
import './baguette-box'
import './form'
import './googlemapsinfobox'
import './masonry'
import './bootstrap';
import './mmenu'
import './objectfit-fallback'
import './teich'
import './event'
import './podcast'


onReady(() => {
    updateActiveItem();
    Accordion();
    Autosize();
    Swiper();
})