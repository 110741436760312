// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

export default () => {

    document.querySelectorAll('.header-slider__swiper').forEach((el) => {
        let init = false;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 1400,
            effect: 'fade',
            autoplay: {
                delay: 7000
            },
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    });


    var logoSwiper = new Swiper('.swiper-container-wly', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        speed: 1400,
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    });



};
