<template>
  <div class="lift-navigation">
    <ul>
      <li v-for="item in headings" @click="scrollToElement(item.el)" :class="{active: item === firstVisibleHeading}">
        {{ item.el.textContent }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import $ from 'jquery';

const headings = ref([]);
const listeners = [];
const scrollOffset = () => (window.innerHeight * 0.35);

const scrollToElement = (el: HTMLElement) => {
  const top = el.getBoundingClientRect()?.top - scrollOffset() + window.scrollY;

  $('html, body').animate({scrollTop: top});
};

function isVisible(el: HTMLElement) {
  if (!el) {
    return false;
  } else {
    const rect = el.getBoundingClientRect();

    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      && rect.right >= 0
    );
  }
}

function isAboveCurrentScrollPosition(el: HTMLElement) {
  if (!el) {
    return false;
  } else {
    const rect = el.getBoundingClientRect();

    return rect.y < scrollOffset() + 1;
  }
}

const firstVisibleHeading = computed(() => {
  return Array.from(headings.value.filter(({above}) => above)).pop() || headings.value.find(({visible}) => visible);
});

onMounted(() => {
  $('html').addClass('lift-navi-active');
  const elements = document.querySelectorAll('main h2');

  elements.forEach((el: HTMLElement) => {
    const visible = ref(false);
    const above = ref(false);

    const listener = () => {
      visible.value = isVisible(el);
      above.value = isAboveCurrentScrollPosition(el);
    };

    listeners.push(listener);
    window.addEventListener('scroll', listener);
    listener();

    headings.value.push({el, visible, above});
  });
});

onUnmounted(() => {
  listeners.forEach(listener => {
    window.removeEventListener('scroll', listener);
  });
});
</script>
<style lang="scss" scoped>
.lift-navigation {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin-bottom: 30px;
      display: flex;
      cursor: pointer;
      @include media(L) {
        margin-bottom: 15px;
      }

      &:before {
        content: '';
        border-radius: 100%;
        width: 12px;
        height: 12px;
        display: inline-block;
        background: $blue-light;
        opacity: 0.4;
        flex-shrink: 0;
        margin: 6px 15px 0 0;
      }

      &.active {
        color: $primary;

        &:before {
          background: $primary;
          opacity: 1;
        }
      }
    }
  }
}

</style>
