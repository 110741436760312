import jQuery from 'jquery';

const $ = jQuery;
import Isotope from 'isotope-layout';
import {slideDown, slideUp, slideStop, isVisible} from 'slide-anim/dist/slide-anim.min';

class Event {

    constructor() {
        this.i18n = (JSON.parse(JSON.stringify(vuei18n.messages)))[vuei18n.locale];
        this.isotopeInstances = [];
        this.isoptpeInit();
        this.initEvent();
    }

    rearrangeEventGrid() {
        if (this.isotopeInstances.length > 0) {

            this.isotopeInstances.forEach(isotopeInstance => {
                console.log(isotopeInstance);
                isotopeInstance.layout();
            });
        }
    }

    loadMap(id, element) {
        var styleArray = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];

        var mapOptions = {
            zoom: 16,
            scrollwheel: false,
            center: {lat: -34.397, lng: 150.644},
            //		center: new google.maps.LatLng(center_lat, center_lng),
            styles: styleArray
        };

        var map = new google.maps.Map(element, mapOptions);
        return map;
    }

    openEvent(elementToOpen) {
        this.rearrangeEventGrid();
        if (elementToOpen.dataset.addressmap !== "") {
            var infoBoxes = [];
            var markers = [];
            var map = this.loadMap(
                elementToOpen.dataset.eventid,
                $(elementToOpen).find('.events__event__detail__map').first().get(0)
            );
            var geocoder = new google.maps.Geocoder();
            this.geocodeAddress(geocoder, map, elementToOpen.dataset.addressmap);
            google.maps.event.addListener(map, "click", (event) => {
                elementToOpen.setOptions({scrollwheel: true});
            });
        }
    }

    initEvent() {
        // Filter Navigation fixed
        if ($('.event-filter').length) {
            $('body').addClass('event-filter__exists');
            var distance = $('.event-filter').offset().top - 100;
            var $window = $(window);

            $window.bind('scroll', function () {
                if ($window.scrollTop() >= distance) {
                    $('body').addClass('fixed-event-filter');
                } else {
                    $('body').removeClass('fixed-event-filter');
                }
            });
        }


        // Open Events
        $('.events__event__arrow').click((event) => {
            if (!$(event.currentTarget).hasClass("open")) {
                let elementToOpen = $(event.currentTarget).parent().siblings('.events__event__detail').get()[0];
                $('.events__event__arrow').removeClass('open');
                $('.events__event').removeClass('active');

                const openEvents = document.querySelectorAll('.events__event__detail[style*="display: block"]');
                openEvents.forEach(openEvent => {
                    slideUp(openEvent, {
                        duration: 500,
                        display: 'block'
                    }).then(() => {
                        this.rearrangeEventGrid();
                    });
                });

                $(event.currentTarget).addClass('open');
                $(event.currentTarget).parents('.events__event').addClass('active');
                slideDown(elementToOpen, {
                    duration: 500,
                    display: 'block'
                }).then(() => {
                    this.openEvent(elementToOpen);
                });
            } else {
                $('.events__event__arrow').removeClass('open');
                $('.events__event').removeClass('active');
                const openEvents = document.querySelectorAll('.events__event__detail[style*="display: block"]');
                openEvents.forEach(openEvent => {
                    slideUp(openEvent, {
                        duration: 500,
                        display: 'block'
                    }).then(() => {
                        this.rearrangeEventGrid();
                    });
                });


            }
        });
    }


    // Isotope
    // https://codepen.io/shainanigans-1472169587/pen/RKEjzz
    isoptpeInit() {

        var categoryFilters = [];
        var categoryFilter;
        if (document.querySelector('.eventgrid') !== null) {

            const eventGrids = document.querySelectorAll('.eventgrid');



            eventGrids.forEach(eventGrid => {


                let isotopeInstance = new Isotope(eventGrid, {
                    // options
                    itemSelector: '.grid-item',
                    layoutMode: 'masonry',
                    masonry: {
                        gutter: 50
                    },
                    filter: function () {
                        var $this = $(this);

                        if (categoryFilters.length === 0) {
                            return true;
                        }
                        // category
                        var categoryResult = true;
                        for (var i = 0; i < categoryFilters.length; i++) {
                            var categoriesStr = $this.data('eventcategory') + '';
                            var categoriesArr = categoriesStr.split(',');
                            if (categoriesArr.includes(categoryFilters[i])) {
                                categoryResult = true;
                            } else {
                                categoryResult = false;
                            }
                        }
                        // apply filters
                        return categoryResult;
                    }
                });
                this.isotopeInstances.push(isotopeInstance);

            });


            // bind filter button click
            $('.event-filter__content__single').on('click', (event) => {

                var catid = $(event.currentTarget).attr('data-filter');
                if (catid === '*') {
                    categoryFilters = [];
                } else {
                    categoryFilters = [catid];
                }

                this.isotopeInstances.forEach(isotopeInstance => {
                    isotopeInstance.arrange();
                });
            });

            setTimeout(() => {
                this.isotopeInstances.forEach(isotopeInstance => {
                    isotopeInstance.arrange();
                });
            }, 450);
        }

        // change is-checked class on buttons
        $('.button__filter').on('click', (event) => {
            $(event.currentTarget).siblings().removeClass('is-checked');
            $(event.currentTarget).addClass('is-checked');
        });
    }


    geocodeAddress(geocoder, resultsMap, address) {
        geocoder.geocode({'address': address}, function (results, status) {
            if (status === 'OK') {
                resultsMap.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: resultsMap,
                    position: results[0].geometry.location
                });
            } else {
//                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }
};

export default new Event();