<template>
  <div class="custom-control custom-checkbox">
    <label class="custom-control-label" :for="name">
      <input type="checkbox" class="filled-in" :id="name" :name="name" v-model="model" :value="value" :disabled="disabled" @change="onChanged($event)" />
      <slot>
        <span>{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script setup>
import { computed, defineEmits } from 'vue';

const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object] },
  label: { type: String, required: true },
  name: { type: String, required: true },
  disabled: { type: Boolean, required: false, default: () => false },
});

const emit = defineEmits(['update:modelValue']);
const emitValue = (value) => emit('update:modelValue', value);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emitValue(value);
  },
});

const onChanged = ($event) => {
  // make sure to sync the checked status of the checkbox. it could happen that the checked status has changed
  // without actually emitting the same value
  $event.target.checked = model.value;
};
</script>
