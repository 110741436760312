import {registerCustomElements} from "./custom-elements";

//
registerCustomElements();

const nodes = document.querySelectorAll('[data-vue-instance]');

if (nodes.length > 0) {
    import('./vue').then(module => {
        const CreateVue = module.default;

        for (const node of nodes) {
            CreateVue(node);
        }
    });
}